const DataTwo = [

{
   
    id: "4",
    icon: "fa-brands fa-searchengin service-icon",
    title: " SEO",
    features: [
        "All Kind Of Websites",
        "Setup E-Commerce Store",
        "Product Uploading",
        "Elementor",
        "WP Bakery Builder"
      ]

},
{

    id: "5",
    icon: "fa-solid fa-pen-nib service-icon",
    title: "Content Writing",
    features: [
        "All Kind Of Websites",
        "Setup E-Commerce Store",
        "Product Uploading",
        "Elementor",
        "WP Bakery Builder"
      ]

},
{

    id: "6",
    icon: "fa-solid fa-paintbrush service-icon",
    title: "Graphics Designing",
    features: [
        "All Kind Of Websites",
        "Setup E-Commerce Store",
        "Product Uploading",
        "Elementor",
        "WP Bakery Builder"
      ]

}

]

export default DataTwo;