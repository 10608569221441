const myeducation = [
    {
 
      id: "1",
      degree: "Bachelor of Commerce (B.Com)",
      institute: "University Of Karachi",
      duration: " July, 2021 - Present",
    },

    {
 
        id: "2",
        degree: "Front End Web Development (Certification)",
        institute: "Tech Pakistan",
        duration: " Oct 2020",
      },

      {
 
        id: "3",
        degree: "Complete Inter In Commerce (I.COM)",
        institute: "Superior Govt College, Karachi, Pakistan",
        duration: " May, 2018 - Aug 2020",
      },

      {
 
        id: "4",
        degree: "Complete Matriculation",
        institute: "Airport Grammar School, Karachi, Pakistan",
        duration: " April, 2018",
      },
    ]

    export default myeducation