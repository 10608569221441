const ReviewData = [


    {
        icon: "fa fa-quote-left review-icon",
        review: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
        ceo: "Humera",
        company: "CEO, Eracos"
    },
    {
        icon: "fa fa-quote-left review-icon",
        review: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
        ceo: "Husain",
        company: "CEO, Sprachschule"
    },
    {
        icon: "fa fa-quote-left review-icon",
        review: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
        ceo: "Yama",
        company: "CEO, Fancy Space"
    },
    {
        icon: "fa fa-quote-left review-icon",
        review: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
        ceo: "Zain Osmani",
        company: "CEO, Zeetek"
    }

]

export default ReviewData;