import React from 'react'
import TopBar from '../../TopBar';

const MyPartner = () => {
  return (
    <div>
    
    <TopBar
    pageTitle= "My Partner"
   />
   
   </div>
  )
}

export default MyPartner